.swiper {
  width: 100%;
  height: 100%;
}

.container__carousel {
  width: 85%;
  border-radius: 10px;
  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  */

.container__carousel__img {
  background-size: cover;
  background-position: center center;
  height: 40vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
 
}
.carousel__img__abstract {
  /* background-image:url(https://res.cloudinary.com/ijac-it-solutions/image/upload/v1678639454/img/abstract_wibshm.jpg); */
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
}

 .carousel__img__abstract > h3, p {
  font-family: "ConfortaRegular";
  font-weight: bold;
  text-align: center;
  color: var(--color-white);
} 

.carousel__img__abstract2  {
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );

}

.carousel__img__abstract2 > h3, p {
  font-family: "ConfortaRegular";
  font-weight: bold;
  text-align: center;
  color: var(--color-white);
} 

.carousel__img__abstract3 {
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
}

.carousel__img__abstract3 > h3, p {
  font-family: "ConfortaRegular";
  font-weight: bold;
  text-align: center;
  color: var(--color-white);
} 



@media screen and (max-width: 600px) {
  .container.header__container{
    width: var(--container-width-sm);
  }

  .container__carousel__img {
  height: 23vh;
   
  }

  .carousel__img__abstract > h3 {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 


  .carousel__img__abstract2 > h3 {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 

  .carousel__img__abstract3 > h3 {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 

  .carousel__img__abstract > p {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 

  .carousel__img__abstract2 > p {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 

  .carousel__img__abstract3 > p {
    font-family: "ConfortaRegular";
    text-align: center;
    font-size: 14px;
    max-width: 20ch;
  } 

 
}



