.testimonials__section {
  background-color: transparent;
  /* set borders */
  border: 1px solid var(--color-primary);
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;

}

.container.testimonials__container {
  width: 70%;
  padding-bottom: 4rem;
}

.testimonials__title {
  padding-top: 1rem;
}

.spinner {
  /* Set Spinner to center */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1rem;

}

.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant)
}

.testimonial {
  background: var(--color-bg-variant); 
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.swiper-slide {
 /*  background: var(--color-bg-variant); */
 background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
}

.testimonial:hover {
  background-color: transparent;
  cursor: pointer;
}

.client__name {
  font-size: 1.2rem;
  font-family: "ConfortaRegular";
  font-weight: 500;
  color: #b4a4b9;
  margin-bottom: 0.5rem;
}

.client__company {
  font-size: 1rem;
  font-family: "ConfortaRegular";
  color: var(--color-white);
}

.client__review {
  color: var(--color-light);
  font-family: "ConfortaRegular";
  font-size: 1rem;
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
  background: var(--color-primary);
}


/* Media Queries for Tablet Devices */

@media screen and (max-width: 1024px) {
  .container.testimonials__container {
      width: 60%;
  }
}


/* Media Queries for Mobile Devices */

@media screen and (max-width: 600px) {
  .testimonials__section {
      width: 95%;
      margin-top: 1rem !important;
  }
  .container.testimonials__container {
      width: var(--container-width-sm);
  }
  .client__review {
      width: var(--container-width-sm);
  }

  .testimonials__section {
    margin-top: 4rem;
  }

  .spinner {
    /* Set Spinner to center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 1rem;
  
  }

  .testimonials__title {
    font-size: 15px;
  }

}