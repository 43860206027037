.Count {
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  margin: 1rem;
/*   box-shadow: 1px 2px 2px #3e3f3f; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Count:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: pointer;
}

.aboutUs__icon {
  height: 30px;
  width: 30px;
  margin-bottom: 1rem;
}

.aboutUs__text {
  font-size: 0.95rem;
  font-family: "ConfortaRegular";
  color:#fff;
}

.aboutUs__text__counter {
  padding-top: 10px;
  font-size: 2.5rem;
  font-family: "ConfortaRegular";
  font-weight: bold;
  /* set shadow to text */
  text-shadow: 1px 1px 1px #3e3f3f;
}