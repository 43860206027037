footer {
  background-image: linear-gradient( 111.4deg,  rgba(27,24,113,1) 6.5%, rgba(7,7,9,1) 93.2% );
  padding: 3rem 0;  
  text-align: center;
  font-size: 0.9rem;
  margin-top: 1rem;
}

footer a {
  color: var(--color-white);
  text-decoration: none;
  font-family: "ConfortaRegular", sans-serif;
}

.social-icon-wrapper {
  background-color: var(--color-bg);
  color: white;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
}

.footer__logo {
  height: 3.5rem;
  width: 3.5rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__link:hover {
  color: #6abd45;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}


.footer__socials a {
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
  transition: var(--transition);
}

.footer__icon {
  font-size: 1.7rem;
}

.footer__socials a:hover {
  color: #6abd45;
}

.footer__copyright {
  margin-bottom: 1rem;
  color: var(--color-white);
  font-family: "ConfortaRegular";
}


/* Media Queries for Mobile Devices */

@media screen and (max-width: 600px) {
  /*center permalinks */
  .permalinks {
      gap: 1rem;
      margin-right: 20px;
  }
  .footer__socials {
      margin-bottom: 2.6rem;
  }
  footer {
    margin-top: 1rem;
  }
}