/* CSS Global settings */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  
}

/* Custom Fonts */
@font-face {
  font-family: "SphereFez"; /*Can be any text*/
  src: local("SphereFezRegular"),
    url("./fonts/sphere-fez-font/SphereFez-8MAzJ.ttf") format("truetype");
}

@font-face {
  font-family: "ConfortaRegular";
  src: local("ConfortaRegular"),
    url("./fonts/comfortaa/Comfortaa-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ConfortaLight";
  src: local("ConfortaLight"),
    url("./fonts/comfortaa/Comfortaa-Light.ttf") format("truetype");
}

/* Roots variables */

:root {
    --color-bg: #333333;
    --color-bg-variant: #242424; 
    --color-bg2: #6d2366;
    --color-bg-variant2: #361132; 
    --color-primary: #24E360;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-secondary: #174273;
    --color-secondary-variant: #0f2b4b;
    --color-white: #fffbf6;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

body {
font-family: "ConfortaRegular", sans-serif;
background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, #0e1016 93.2% );
overflow-x: hidden; 
}

/* General Styles */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    font-family: "ConfortaLight", sans-serif;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 2rem;
}

section>h2,
section>h5 {
    text-align: center;
    color: var(--color-light);
}

section>h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
  text-shadow:
    0 0 5px var(--color-primary),
}


.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
  text-decoration: none;
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* CSS for Mobile Devices */
@media screen and (max-width: 600px) {
  section {
    margin-top: 4rem;
  }

  section>h5 {
    text-align: center;
    color: var(--color-light);
    font-size: 17px ;
}
}





