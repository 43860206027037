header {
  height: 55vh;
  overflow: hidden;
}

.header__img {
  background-color: black;
}

/* CSS for ijac Photo */

.ijacPhoto {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-size: cover; 
  z-index: -1;
  opacity: 0.2;
}

.header__titleH1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -500%);
  color: white;
  font-size: 3rem;
  text-align: center;
  font-family: "SphereFez";
  background-clip: text;
  background: -webkit-linear-gradient(#eee, #c432b6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header__titleH2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -630%);
  color: white;
  font-size: 2rem;
  text-align: center;
  font-family: "SphereFez";
  background-clip: text;
  background: -webkit-linear-gradient(#eee, #c432b6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 680px }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color:#24E360 }
}


.header__subtitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -650%);
  color: white;
  font-size: 1.2rem;
  text-align: center;
  font-family: "ConfortaRegular";
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.container__CTA {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -300%);
}

/* CSS for Contact Button */

.btn-primary {
  margin: 0 auto;
  position: relative;
  width: 150px;
  height: 40px;
  color: black;
  /* background-color: #6abd45; */
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
  box-shadow: 2px 2px 3px #333333;
}

.btn-primary::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 10%;
  width: 80%;
  height: 70%;
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
  z-index: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(5px);
}

.btn-primary:hover::before {
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(25px);

}

.btn-primary:hover {
  color: white;
  background-color: var(--color-secondary);
  box-shadow: 2px 2px 3px #333333;
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
} 

.btn-primary::after {
  content: "Contactanos";
  text-align:center;
  line-height:35px;
  position:absolute;
  font-size: 15px;
  font-family: "ConfortaRegular";
  border-radius: 5%;
  top:6%;
  left: 3%;
  width: 95%;
  height: 89%;
  z-index: 5;
  color: #ecf0f3; 
  display: block;
  background-color: #121145;
}


/* Media Queries for mobile devices */

@media screen and (max-width: 600px) {
  .container.header__container{
    width: var(--container-width-sm);
  }

  .header__titleH2 {
   
    transform: translate(-40%, -1210%);
    font-size: 1rem;
   

  }

  .header__subtitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -1150%);
    color: white;
    font-size: 10px;
    text-align: center;
    font-family: "ConfortaLight";
    max-width: 62ch;

  }

 
}