.about__container__section {
  background-color: transparent;
  /* set borders */
  border: 1px solid var(--color-primary);
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  align-items: center;
}

.about__title {
  padding-top: 2rem;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
  margin-top: 1rem;
}

.about__me-image {
  margin-top: 1rem;
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
  height: 26rem;
  width: 26rem;
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__card {
  background: #121145;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  place-items: center;
  transition: var(--transition);

}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
  font-family: "ConfortaRegular";
}



/*Media Queries Tablet devices*/

@media screen and (max-width: 1024px) {
  .about__container {
      grid-template-columns: 1fr;
      gap: 0;
  }
  .about__me {
      width: 50%;
      margin: 8rem auto 4rem;
  }
  .about__content p {
      margin: 1rem 0 1.5rem;
  }
}


/* Media Queries for Mobile Devices */

@media screen and (max-width: 600px) {
  .about__container__section {
    width: 95%;
    margin-top: 1rem;
  }
  .about__me {
      width: 65%;
      margin: 0 auto 3rem;
  }
  .about__cards {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
  }
  .about__content {
      text-align: center;
      margin: 1.5rem 0; 
  }


  
.about__me-image {

  height: 15rem;
  width: 15rem;
}

.about__content > p {
  color: var(--color-light);
  font-family: "ConfortaRegular";
  font-size: 15px;
  width: 90%;
  text-align: justify;
  text-align: center;
  margin: 0 auto;
}

}



  
