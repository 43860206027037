
/*Css for Services Container */

.services__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 2rem;
  width: 85%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: transparent;
  border: 1px solid #24E360;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;

}

.services__title {
  color: #24E360;
  text-shadow: 0 0 5px #24E360;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.services__container__card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 1rem;
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
  border-radius: 10px;
  margin-bottom: 1rem;
}

.services__container__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}
.services__container__text h3 {
  color: var(--color-white);
  font-family: "ConfortaRegular";
  font-size: 24px;
  margin-bottom: 1rem;
  text-shadow:
  0 0 2px #fff;
  width: 80%;
}

.services__container__text p {
  color:var(--color-white);
  font-family: "ConfortaRegular";
  font-size: 1rem;
  margin-bottom: 1rem;
  max-width: 60ch;
  width: 80%;
}

.services__container__img img {
  height: 200px;
  width: 300px; 
  object-fit:contain;
  border-radius: 1rem;
}

/* Media Query for mobile devices */
@media screen and (max-width: 600px) {
 
  .services__container__card {
    display: flex;
    flex-direction:column-reverse;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .services__container__card__design {
    flex-direction: column;

  }

  .services__container__text {
  
    width: 70%;
  
  }

  .services__container {
   
    width: 95%;
 
  }

  .services__container__img img {
    height: 150px;
    width: 250px; 
    object-fit:contain;
    border-radius: 1rem;
    margin-top: 10px;
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 1181px)
{
  .services__container__card {
    display: flex;
    flex-direction:column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .services__container__card__design {
    flex-direction: column;

  }
  .services__container__img img {
    height: 90%;
    width: 400px; 
    border-radius: 1rem;
  }
}



