
.contact__container__section {
  background-color: transparent;
  /* set borders */
  border: 1px solid var(--color-primary);
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;

}

.container.contact__container {
  display: flex;
  flex-direction: column;
  gap: 12%;
  margin-top: 3rem;
  width: 50%;
}
/* CSS for Form */

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__title {
  color: 
  #6abd45 !important;
}

.contact__subtitle {
  padding: 1rem;
}



/* Css for input's Form */

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  transition: all .3s;
}

textarea:focus {
  outline: none;
  border: 2px solid 
  #6abd45;
  box-shadow: 0 0 0 0.2rem rgba(192, 192, 192, 0.25);
}

input:focus {
  outline: none;
  border: 2px solid 
  #6abd45;
  /* set box-shadows */
  box-shadow: 0 0 0 0.2rem rgba(192, 192, 192, 0.25);
}
textarea::placeholder{
  color: #756760;
  font-family: "ConfortaRegular";
  font-size: 1rem;
  transition: all .3s;
}

input::placeholder{
  color: #756760;
  font-family: "ConfortaRegular";
  font-size: 1rem;
  transition: all .3s;
}

input:focus::placeholder {
  opacity: 1;
  animation-delay: 0.2s;
}

/* Set button to center horizontally */

.btn-primary {
  margin: 0 auto;
  position: relative;
  width: 150px;
  height: 40px;
  color: black;
  /* background-color: #6abd45; */
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
  box-shadow: 2px 2px 3px #333333;
}

.btn-primary::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 10%;
  width: 80%;
  height: 70%;
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
  z-index: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(5px);
}

.btn-primary:hover::before {
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(25px);

}

.btn-primary:hover {
  color: white;
  background-color: var(--color-secondary);
  box-shadow: 2px 2px 3px #333333;
  background: linear-gradient(60deg,#24E360,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82 );
} 

.btn-primary::after {
  content: "Enviar Mensaje";
  text-align:center;
  line-height:35px;
  position:absolute;
  font-size: 15px;
  font-family: "ConfortaRegular";
  border-radius: 5%;
  top:6%;
  left: 3%;
  width: 95%;
  height: 89%;
  z-index: 5;
  color: #ecf0f3; 
  display: block;
  background-color: #3f143a;
}

/* WhatsApp Floating Icon */

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:25px;
	right:25px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #333333;
  z-index:100;
}


.my-float{
	margin-top:16px;
}

.contact__whatsApp_logo {
  padding-top: 5px; 
  height: 40px;
  width: 40px;
}

.contact__whatsApp_logo:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

/* CSS for container location */
.container__location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* set border */
  border: 2px solid var(--color-primary-variant);
  border-radius: 0.5rem;
  width: 70%;
  margin-top:20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  /* margin: 50px auto 0 auto; */
}

.container__location > p {
  color: #9b9bad;
  font-family: "ConfortaRegular";

}

.container__location__icon {
  color: #24E360;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Media Queries for Tablet devices */

@media screen and (max-width: 1024px) {
  .container.contact__container {
      grid-template-columns: 1fr;
      gap: 2rem;
  }
}


/* Media Queries for mobile devices */

@media screen and (max-width: 600px) {

  .contact__container__section {
    width: 95%;
    margin-top: 1rem;
  }
  .container.contact__container {
      width: var(--container-width-sm);
  }

  input,
textarea {
  width: 100%;
}

.container__location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* set border */
  border: 2px solid var(--color-primary-variant);
  border-radius: 0.5rem;
  width: 70%;
  margin-top:10px;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:25px;
	right:25px; 
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #333333;
  z-index:100; 
}

}

