
nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  width: 300px;
  z-index: 100;
  box-shadow: 21px 2px 48px -1px rgba(0, 0, 0,0.09);
  
}

.inner-nav {
  position: absolute;
  top: 0;
  bottom: 0; 
  width: 100%;
  background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
  border-radius: 1%;
  /* set background shadow */
  box-shadow: 21px 2px 48px -1px rgba(0, 0, 0,0.09);
}

button.toggle {
  background-color: #121145;
  color: #fff;
  z-index: 101;
  position: fixed;
  outline: 0;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  top: 18px;
  left:15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

nav .inner-nav ul {
  padding: 0;
  position: relative;
  top: 100px;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0,0.2);
}

nav .inner-nav ul li {
  list-style: none;
  cursor: pointer;
  line-height: 3;
  padding: 0 25px;
  border-bottom: 1px solid rgba(0, 0, 0,0.2);
}

nav .inner-nav li a {
  font-size: 1rem;
  color:#fff;
  text-decoration: none;
  transition: 1s all ease-in-out;
  font-family: "ConfortaRegular";
}

nav .inner-nav li a:hover {
  color: #24E360;
}

/* Css for Logo ijac */

.logoContainer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  
} 

.ijacLogoPos {
  margin-bottom: 5rem;
  width: 80px;
  height: auto;
}

/* CSS for mobile devices */
@media screen and (max-width:600px){
  nav {
    width: 150px;   
  }
  .logoContainer {
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
  } 
  .ijacLogoPos {
    margin-bottom: 3rem;
    width: 70px;
    height: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1181px){
  nav {
    width: 200px;   
  }
  .logoContainer {
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
  } 
  .ijacLogoPos {
    margin-bottom: 2rem;
    width: 70px;
    height: auto;
  }
}